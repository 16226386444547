@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap');
body{
    font-family: 'Yeseva One', 'cursive';
    font-family: 'Roboto Slab', serif;
} */
html {
    scroll-behavior: smooth;
  }