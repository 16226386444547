@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap");
.title {
  -webkit-text-fill-color: white;
  font-family: "Roboto";
}
#header {
  background-image: url("../Assets/waves2.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;
  display: flex;
  transition: transform 1s ease-in-out;
}

.bg-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.bg-video.active {
  opacity: 1;
  position: absolute;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
